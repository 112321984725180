import {useMemo} from "react"
import {Layer, Marker, Source} from "react-map-gl"
import styled from "styled-components"
import {RouteColors} from "../../../../library/helper/route"
import {random} from "lodash/number"

const TerminalPointsLayer = ({routes, activeRoute}) => {
	const data = useMemo(() => {
		let result = {
			type: "FeatureCollection",
			features: []
		}

		if (!routes || !routes.length) return result

		for (let i = 0; i < routes.length; i++) {
			const route = routes[i]
			route.color = RouteColors[random(0, RouteColors.length - 1)]
			for (const point of route.points) {
				result.features.push({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [point.location[1], point.location[0]],
					},
					properties: {
						id: point.id,
						route_id: i,
						position: point.position,
						color: route.color
					}
				})
			}
		}

		return result
	}, [routes])

	const POINTS_LAYER = useMemo(() => {
		const result = {
			id: "points",
			type: "circle",
			metadata: {type: "point"},
			paint: {
				"circle-radius": 10,
				"circle-color": ["get", "color"],
				"circle-stroke-width": 1,
				"circle-stroke-color": "#000000",
				"circle-opacity": activeRoute ? 0 : 1,
				"circle-stroke-opacity": activeRoute ? 0 : 1,
			},
			layout: {
				// сверху точки из активного МЛ или наведенного
				"circle-sort-key": ["case", ["==", ["get", "route_id"], activeRoute], ["+", 5, ["get", "position"]], ['in', ['get', 'status'], ['literal', ['canceled:manager', 'canceled:driver', 'canceled:api']]], 3, ['in', ['get', 'status'], ['literal', ['on_way', 'arrived']]], 2, ['==', ['get', 'status'], 'created'], 1, 0]
			},
			filter: ["any", ["all"]]
		}

		// ставим точки активного МЛ наверх, скрываем остальные и уходим
		if (activeRoute) {
			result.filter[1].push(["==", ["get", "route_id"], activeRoute])
			return result
		}


		return result
	}, [activeRoute])

	const activeRoutePoints = useMemo(() => {
		if (!activeRoute) return []
		const result = []
		for (const point of routes[activeRoute].points) {
			result.push(point)
		}

		return result.sort((a, b) => a.position - b.position)
	}, [activeRoute])

	return (
		<>
			<Source type="geojson" data={data}>
				<Layer {...POINTS_LAYER} />
			</Source>
			{activeRoutePoints.map((point, index) => (
				<Marker key={"marker-" + index}
						longitude={point.location[1]}
						latitude={point.location[0]}>
					<ActivePoint color="#8bc565">{point.position}</ActivePoint>
				</Marker>
			))}
		</>
	)
}

export default TerminalPointsLayer

const ActivePoint = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 99px;
    border: solid 1px #000000;
    background: ${props => props.color};
    font-family: Arial;
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    cursor: pointer;
`
