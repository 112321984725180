import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import {Button} from "antd"
import styled from "styled-components"
import 'swiper/css'
import MultiServiceSwiper from "./components/MultiServiceSwiper"
import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {faArrowRight} from "@fortawesome/pro-light-svg-icons"
import Icon from "../../components/Icon/Icon"

const HomeScreen = () => {
	const navigate = useNavigate()

	const [section, setSection] = useState('routing')

	useEffect(() => {
		document.title = 'АТС24 — цифровая логистика, маршрутизация, мониторинг, мобильное приложение для водителя'
	}, [])

	const sections = {
		"routing": {
			title: 'Маршрутизация',
			description: 'Маршрутизируй вместе со своей командой прямо в браузере.',
			plural: 'маршрутизацию'
		},
		"monitoring": {
			title: 'Мониторинг',
			description: 'Местоположение, статусы точек, скоростной режим, контроль топлива и температуры..',
			plural: 'мониторинг'
		},
		"mobile-app": {
			title: 'Мобильное приложение',
			description: 'Путевой лист, электронные накладные, архив поездок, график работы и многое другое.',
			plural: 'мобильное приложение'
		}
	}

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Цифровая логистика" large>
					<p>Более 20 современных инструментов для логистики, объединённых на одной платформе.</p>
					<p>
						<Button onClick={() => navigate('/contact')} color="default" variant="solid" size="large">
							Попробовать бесплатно
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} gap={0}>
				<Section>
					<img width="100%" src="./assets/img/png/home/main.png"/>
				</Section>
				<ButtonsWrapper>
					{Object.entries(sections).map(([key, value]) => (
						<Button
							onClick={() => setSection(key)} color="default"
							variant={section === key ? "solid" : "text"}
							shape="round" size="large"
						>
							{value.title}
						</Button>
					))}
				</ButtonsWrapper>
				<SectionDescription>
					{sections[section].description}
				</SectionDescription>
				<SectionLink>
					<a onClick={() => navigate('/' + section)}>
						Перейти в {sections[section].plural} <Icon style={{marginLeft: 5}} name={faArrowRight}/>
					</a>
				</SectionLink>
			</ContentBlock>

			<ContentBlock background="#F3FFE3">
				<ContentCard title="Простота">
					<p>
						Наши интерфейсы интуитивно понятны, и любой сотрудник освоит их всего за час. Все экраны были
						разработаны с участием логистов, водителей и руководителей отделов.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#E4FF97">
				<ContentCard title="Скорость">
					<p>
						Потрясающая скорость работы даже на офисных ПК. Терминал и маршрутизация эффективно обрабатывают
						большое количество маршрутов, а карта без задержек может отображать более 10 000 точек
						одновременно.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#FFF2DF" bottom={0}>
				<ContentCard title="Сервисы">
					<p>
						Мы внимательно следим за тенденциями рынка логистики и регулярно запускаем
						новые, современные сервисы, доступные сразу всем клиентам.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#FFF2DF" top={0}>
				<MultiServiceSwiper/>
			</ContentBlock>
			<ContentBlock background="#f3f3f3" bottom={0}>
				<ContentCard title="Приложение руководителя" textColor="#000000" radius={0}
							 media={{
								 url: "/assets/img/jpg/home/director-app.jpg",
								 width: 780
							 }}
				>
					<p>
						Всегда под рукой краткая статистка плюс полная информацию о маршрутах, точках и водителях за
						любой день.
					</p>
					<p>
						<Button onClick={() => navigate('/services/director')} color="default" variant="solid"
								size="large">
							Подробнее о приложении
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
		</>
	)
}

export default HomeScreen

const Section = styled.div`
    -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    overflow: hidden;
`
const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
`
const SectionDescription = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 24px;
    
`
const SectionLink = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 18px;
    
`
