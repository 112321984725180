import styled from "styled-components"
import {Link, useNavigate} from "react-router-dom"
import {Button} from "antd"
import Icon from "../../components/Icon/Icon"
import {faChevronDown} from "@fortawesome/pro-regular-svg-icons/faChevronDown"
import logo from "../../assets/svg/logo.svg"
import {UserOutlined} from "@ant-design/icons"
import {useState} from "react"

const menu = [
	{key: 'routing', title: 'Маршрутизация'},
	{key: 'monitoring', title: 'Мониторинг'},
	{key: 'mobile-app', title: 'Мобильное приложение'},
	{key: 'services', title: 'Сервисы', children: true},
	{key: 'price', title: 'Тарифы'},
	{key: 'contact', title: 'Контакты'}
]

const services = [
	/*{key: 'reports', title: 'Отчёты', description: 'Система адресной доставки отчётов'},*/
	{key: 'motivation', title: 'Мотивация водителей', description: 'Бонусная система и заработная плата водителей'},
	{key: 'fines', title: 'Штрафы ГИБДД', description: 'Проверка штрафов с привязкой к водителю'},
	{key: 'telegram', title: 'Телеграм бот', description: 'Оповещение сотрудников о важных событиях'},
	{key: 'temperature', title: 'Контроль температуры', description: 'Инструменты для контроля температурного режима'},
	{
		key: 'observers',
		title: 'Кабинет клиента',
		description: 'Позвольте вашим партнёрам и клиентам мониторить доставку'
	},
	{key: 'director', title: 'Руководителю', description: 'Доступ к опертивной информации в смартфоне'}
	/*{key: 'TV терминал', title: 'Контроль температуры', description: 'Доступ вашим клиентам к мониторингу доставки'},
	/*{key: 'temperature', title: 'Контроль температуры', description: 'Доступ вашим клиентам к мониторингу доставки'},
	{key: 'observers', title: 'Кабинет клиента', description: 'Доступ вашим клиентам к мониторингу доставки'},
	{key: 'fleet', title: 'Для своего парка', description: 'Доступ вашим клиентам к мониторингу доставки'}*/
	/*{key: 'checklists', title: 'Чек-листы', description: 'Предрейсовые чек-листы и задания на точках'},
	{key: 'transport', title: 'Управление парком', description: 'Сервис, техобслуживание, приёмка-передача ТС'},
	{key: 'observers', title: 'Клиентский доступ', description: 'Самостоятельный контроль доставки со стороны клиента'},
	{key: 'director', title: 'Приложение логиста', description: 'Доступ к опертивной информации в смартфоне'},*/


	//{key: 'camera', title: 'Умная камера'},
	//{key: 'documents', title: 'Учёт документов и тары'},
]

const Header = () => {

	const navigate = useNavigate()
	const [open, setOpen] = useState(false)

	return (
		<>
			<Fixed>
				<MenuWrapper>
					<Logo onClick={() => navigate('/')}>
						<img style={{height: 40}} src={logo}/>
					</Logo>
					<Navigation>
						{
							menu.map(item => (
								<MenuItem
									key={"menu-item-" + item.key}
									onClick={() => {
										if (item.children) {
											setOpen(open => !open)
										} else {
											setOpen(false)
											navigate('/' + item.key)
										}
									}}
								>
									{item.title} {item.children ? <Icon name={faChevronDown}/> : null}
								</MenuItem>
							))
						}
					</Navigation>
					<Login>
						<Link to="https://app.ats24.ru" target="_blank">
							<Button color="default" variant="solid" icon={<UserOutlined/>}>
								Аккаунт
							</Button>
						</Link>
					</Login>
				</MenuWrapper>

				<SubMenuWrapper className={`top-submenu ${open ? 'open' : ''}`}>
					<SubMenuContent>
						{services.map(menu => (
							<SubMenuItem key={menu.key} onClick={() => {
								setOpen(false)
								navigate('/services/' + menu.key)
							}}>
								<SubMenuTitle>{menu.title}</SubMenuTitle>
								<SubMenuDescription>{menu.description}</SubMenuDescription>
							</SubMenuItem>
						))}

					</SubMenuContent>
				</SubMenuWrapper>
			</Fixed>

			<SubMenuOverlay onClick={() => setOpen(false)} className={`top-submenu-overlay ${open ? 'open' : ''}`}/>
		</>
	)
}

export default Header

const Fixed = styled.div`
    display: flex;
    position: sticky;
    flex-direction: column;
    top: 0;
    width: 100%;
    z-index: 10;
    align-items: center;
    border-bottom: solid 1px #EEEEEE;
    background-color: #FFFFFF;
`
const MenuWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 1320px;
    padding: 15px 25px;
    gap: 8px;
`
const Logo = styled.div`
    display: flex;
    cursor: pointer;
    width: 50px;
`
const Navigation = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 15px;
`
const MenuItem = styled.a`
    font-size: 18px;

    cursor: pointer;
    padding: 8px;
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
`
const Login = styled.div`
    display: flex;
    align-items: center;
`
const SubMenuWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 71px;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    z-index: 10;
    background: #FFFFFF;
    border-bottom: solid 1px transparent;
`
const SubMenuContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1320px;
    padding: 40px 25px 40px 25px;
    gap: 60px;
    row-gap: 40px;
`
const SubMenuItem = styled.div`
    display: flex;
    flex-direction: column;
    width: calc((100% - 180px) / 4);
    gap: 5px;
    cursor: pointer;

    :hover a {
        text-decoration: underline;
    }
`
const SubMenuTitle = styled.a`
    font-size: 24px;
    text-decoration: none;
`
const SubMenuDescription = styled.div`
    opacity: .6;
    font-size: 1.125rem;
`
const SubMenuOverlay = styled.div`
    position: fixed;
    inset: 70px 0 0 0;
    background-color: rgba(0, 0, 0, .35);
    z-index: 9;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.7s ease-in-out, visibility 0s 0.7s;
`

