import {faSnowflake} from "@fortawesome/pro-light-svg-icons"
import {faLeaf} from "@fortawesome/pro-regular-svg-icons"

export const RouteColors = [
	"#FFFF00",
	"#32CD32",
	"#FF6347",
	"#FFA500",
	"#00BFFF",
	"#FF69B4",
	"#1E90FF",
	"#FFD700",
	"#FF7F50",
	"#ADFF2F",
	"#FFB6C1",
	"#00FA9A",
	"#00CED1",
	"#FFDA44",
	"#DA70D6",
	"#FFA07A",
	"#FFDAB9",
	"#F0E68C",
	"#9ACD32",
	"#FF8C00",
	"#66CDAA",
	"#5F9EA0",
	"#DB7093",
	"#DAA520",
	"#FFB5C5",
	"#FF5722",
	"#FFCC00",
	"#FF6F61",
	"#FF8C00",
	"#F08080",
	"#1E90FF",
	"#FFB300",
	"#DDA0DD",
	"#FF5A00",
	"#00FF7F",
	"#FF6347",
	"#FF9AA2",
	"#FFBC42"
]

export const RouteStatuses = {
	"draft": {
		level: -1,
		name: "Черновик",
		color: "#b2ada3"
	},
	"created": {
		level: 0,
		name: "Отправлен",
		color: "#FF9500"
	},
	"accepted": {
		level: 1,
		name: "Подтвержден",
		color: "#b2ada3"
	},
	"performed": {
		level: 2,
		name: "В работе",
		color: "#409FE7"
	},
	"done": {
		level: 3,
		name: "Завершен",
		color: "#87D068"
	},
	"canceled:manager": {
		level: 4,
		name: "Отменили",
		color: "#FF3B30"
	},
	"canceled:api": {
		level: 5,
		name: "Отменили",
		color: "#FF3B30"
	},
	"canceled:driver": {
		level: 6,
		name: "Отклонен",
		color: "#FF3B30"
	}
}

export const RouteStatusFilters = {
	"created": {
		name: "Неактивные",
		color: "#FF9500"
	},
	"performed": {
		name: "В работе",
		color: "#409FE7"
	},
	"done": {
		name: "Завершенные",
		color: "#87D068"
	},
	"canceled": {
		name: "Отмененные",
		color: "#FF3B30"
	}
}

export const TempMode = {
	"freeze": {
		color: "#1677ff",
		icon: faSnowflake
	},
	"fresh": {
		color: "#87D068",
		icon: faLeaf
	}
}
