import styled from "styled-components"
import {List} from 'react-virtualized'
import StatusTag from "../../../components/UI/StatusTag"
import 'swiper/css/scrollbar'

const arr = []

for (let i = 0; i < 100; i++) {
	arr.push(i)
}

const RoutesList = ({routes, activeRoute, setActiveRoute}) => {

	const routeItem = ({key, index, style}) => {
		const route = routes[index]
		return (
			<RouteCard
				key={key}
				id={'route-' + index}
				style={style}
				onClick={() => setActiveRoute(activeRoute => activeRoute !== index ? index : null)}
			>
				<CardContent active={activeRoute === index}>
					<TopLine>
						<DriverName className="highlight">
							{route.driver}
						</DriverName>
						<TotalPoints>
							7 из 17
						</TotalPoints>
					</TopLine>
					<BottomLine>
						<LeftSide>
							{route.name}
						</LeftSide>
						<RightSide>
							<StatusTag color="red" text="Завершен"/>
						</RightSide>
					</BottomLine>
				</CardContent>

			</RouteCard>
		)
	}

	return (
		<Wrapper>
			<List
				width={300}
				height={550}
				rowCount={routes.length}
				itemData={[routes, activeRoute]}
				rowHeight={60}
				rowRenderer={routeItem}
				overscanRowCount={20}
			/>
		</Wrapper>
	)
}

export default RoutesList

const Wrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: calc(50% - 635px);
    bottom: 5px;
    width: 270px;
    user-select: none;

    .ReactVirtualized__List::-webkit-scrollbar {
        width: 10px;
    }

    .ReactVirtualized__List::-webkit-scrollbar-track {
        background: transparent;
        margin-left: 14px;
        transform: translateX(4px);
    }

    .ReactVirtualized__List::-webkit-scrollbar-thumb {
        background: #666666;
        border-radius: 10px;
    }

    gap: 10px;
`

const RouteCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 4px;
`
const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    width: ${props => props.active ? 260 : 250}px;
    height: 70px;
    background: #FFFFFF;
    cursor: pointer;
    padding: 5px 7px 0 4px;
    font-size: 14px;
    border-left: solid 4px ${props => props.active ? 'red' : 'transparent'};
    transition: width ease .3s;

    :hover {
        background-color: #E6F4FF;
    }
`
const TopLine = styled.div`
    display: flex;
    flex: 1;
`
const TotalPoints = styled.div``
const DriverName = styled.span`
    display: flex;
    flex: 1;
    font-weight: 500;
`
const LeftSide = styled.div`
    display: flex;
    width: 100%;
    min-width: 0;
    padding-right: 2px;
`
const RightSide = styled.div`
    display: flex;
    white-space: nowrap;
`
const BottomLine = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`