import http from "../network/http"
import dayjs from "dayjs"
import polyline from "@mapbox/polyline"

export const getTrack = async (route) => {
	let shipments = route.points.map((point, index) => ({
		id: index,
		pickup: {
			id: index,
			location: [37.428407, 55.695073]
		},

		delivery: {
			id: index,
			service: 600,
			location: [point.location[1], point.location[0]],
			time_windows: [[
				dayjs(point.from_at).unix(),
				dayjs(point.to_at).unix()
			]]
		}
	}))

	let vehicles = [{
		id: 0,
		start: [37.428407, 55.695073],
		end: [37.428407, 55.695073]
	}]

	const routing = {assigned: [], unassigned: []}

	let result = await http.post("https://vrp.ms.ats24.ru", {shipments, vehicles})
	if (result.data.routes?.length) {
		return polyline.decode(result.data.routes[0].geometry)
	}

	return null

	/*if (result.routes?.length) {
		routing.assigned = result.routes[0].steps.filter(step => step.type === 'delivery').map(step => ({
			index: step.id,
			arrival: step.arrival,
			location: step.location
		}))

	}
	if (result?.unassigned?.length) {
		routing.unassigned = result.unassigned.filter(step => step.type === 'delivery').map(point => point.id)
	}*/

	/*

	if (routing.unassigned.length) {
		if (routing.assigned.length) {
			vehicles[0].start = routing.assigned[routing.assigned.length - 1].location
		}
		shipments = points.filter(point => routing.unassigned.includes(point.index)).map(point => ({
			id: point.index,
			pickup: {
				id: point.index,
				location: vehicles[0].start,
				...(routing.assigned.length ? {time_windows: [[routing.assigned[routing.assigned.length - 1].arrival, routing.assigned[routing.assigned.length - 1].arrival]]} : [warehouse.location[1], warehouse.location[0]])
			},
			// если стоит приоритет и max значение больше ноля
			priority: metricMinMax ? Math.round(((point.statistic[store.user.settings.routing.optimization.priority] - metricMinMax[0]) / (metricMinMax[1] - metricMinMax[0])) * 100) : 0,
			delivery: {
				id: point.index,
				service: point.service_time * 60,
				location: [point.address_location[1], point.address_location[0]]
			}
		}))

		let result = await HTTP.post("https://vrp.ms.ats24.ru", {shipments, vehicles}, {external: true})
		if (result.routes?.length) {
			result.routes[0].steps.filter(step => step.type === 'delivery').forEach(step => {
				routing.assigned.push({
					index: step.id,
					arrival: step.arrival,
					location: step.location
				})
			})
		}
	}

	routing.assigned.forEach((point, index) => {
		store.points.update(point.index, null, 'position', index + 1)
	})

	await getPolyline(route.id)
	store.queue.addQueue({model_id: route.id, action: 'syncRoute'})
	return true*/
}