import {Layer, Source} from "react-map-gl"
import React, {useMemo} from "react"
import styled from "styled-components"
import {getRhumbLineBearing} from "geolib"
import {random} from "lodash/number"

const TransportTrackLayer = ({track, beforeId = 'transport'}) => {
	const collection = useMemo(() => {
		const collection = {
			line: {
				source: {
					"type": "FeatureCollection",
					"features": [
						{
							"type": "Feature",
							"geometry": {
								"type": "LineString",
								"coordinates": []
							}
						}
					]
				},
				gradient: ["interpolate", ["linear"], ["line-progress"], 0.000001, "#C9C5BE"]
			},
			transport: {
				"type": "FeatureCollection",
				"features": []
			},
			arrows: {
				"type": "FeatureCollection",
				"features": []
			},
			parking: {
				"type": "FeatureCollection",
				"features": []
			},
			speeding: []
		}

		if (!track?.length) return collection

		for (let i = 0; i < track.length; i++) {
			if ((i + 1) % 2 === 0) continue
			track[i][0] = track[i][0] + random(0.000001, 0.00001)
			track[i][1] = track[i][1] + random(0.000001, 0.00001)
			collection.line.source.features[0].geometry.coordinates.push([track[i][1], track[i][0]])

			if (!i) continue

			collection.arrows.features.push({
				"type": "Feature",
				"geometry": {
					"type": "Point",
					"coordinates": [track[i][1], track[i][0]]
				},
				"properties": {
					"latitude": track[i][0],
					"longitude": track[i][1],
					//"timestamp": data.track[i].timestamp,
					//"speed": data.track[i].speed,
					//"temp": data.track[i].temp,
					"heading": Math.round(getRhumbLineBearing(
						{latitude: track[i - 1][0], longitude: track[i - 1][1]},
						{latitude: track[i][0], longitude: track[i][1]}
					))
				}
			})
		}

		return collection

		//if (!data.track.length) return collection

		/*const transport = store.transport.items.find(transport => transport.id === data.id)
		if (!transport) return collection

		collection.transport.features.push({
			"type": "Feature",
			"geometry": {
				"type": "Point",
				"coordinates": [data.track[store.monitoring.tracks[section].interval[1]].location[1], data.track[store.monitoring.tracks[section].interval[1]].location[0]],
			},
			"properties": {
				"id": data.id,
				"latitude": data.track[store.monitoring.tracks[section].interval[1]].location[0],
				"longitude": data.track[store.monitoring.tracks[section].interval[1]].location[1],
				"heading": store.monitoring.tracks[section].interval[1] > 1 ? Math.round(getRhumbLineBearing({
					latitude: data.track[store.monitoring.tracks[section].interval[1] - 1].location[0],
					longitude: data.track[store.monitoring.tracks[section].interval[1] - 1].location[1]
				}, {
					latitude: data.track[store.monitoring.tracks[section].interval[1]].location[0],
					longitude: data.track[store.monitoring.tracks[section].interval[1]].location[1]
				})) : 0,
				"image": 'transport-arrow-' + (dayjs(data.track[store.monitoring.tracks[section].interval[1]].timestamp).isToday() ? transport.last_active.status : 'history'),
				"name": transport.name,
				"gosnumber": transport.gosnumber,
				"last_active_title": transport.last_active.title,
				"last_active_status": transport.last_active.status,
				"distance": 0
			}
		})

		if (data.track.length < 2) return collection

		let startDistance = 0

		for (let i = 0; i < data.track.length; i++) {
			if (i < store.monitoring.tracks[section].interval[0] || i > store.monitoring.tracks[section].interval[1]) continue
			collection.line.source.features[0].geometry.coordinates.push([data.track[i].location[1], data.track[i].location[0]])

			if (!i) continue

			collection.arrows.features.push({
				"type": "Feature",
				"geometry": {
					"type": "Point",
					"coordinates": [data.track[i].location[1], data.track[i].location[0]]
				},
				"properties": {
					"latitude": data.track[i].location[0],
					"longitude": data.track[i].location[1],
					"timestamp": data.track[i].timestamp,
					"speed": data.track[i].speed,
					"temp": data.track[i].temp,
					"heading": Math.round(getRhumbLineBearing(
						{latitude: data.track[i - 1].location[0], longitude: data.track[i - 1].location[1]},
						{latitude: data.track[i].location[0], longitude: data.track[i].location[1]}
					))
				}
			})


			const distance = data.track[i].metrics.distance - data.track[i - 1].metrics.distance

			const total = data.track[store.monitoring.tracks[section].interval[1]].metrics.distance - data.track[store.monitoring.tracks[section].interval[0]].metrics.distance
			const part = distance / total
			const color = data.track[i].temp === null ? '#C9C5BE' : data.track[i].temp <= 0 ? "#36cfc9" : data.track[i].temp <= 5 ? "#52abff" : "#FF5413"

			startDistance += part

			if (i === store.monitoring.tracks[section].interval[0]) {
				collection.line.gradient[3] = 0
				collection.line.gradient[4] = color
			} else if (i === store.monitoring.tracks[section].interval[1]) {
				collection.line.gradient.push(1, color)
			} else {
				collection.line.gradient.push(collection.line.gradient[collection.line.gradient.length - 2] + part, color)
			}

			if (data.track[i].events.speeding) {
				collection.speeding.push({
					"type": "FeatureCollection",
					"features": [{
						"type": "Feature",
						"geometry": {
							"type": "Point",
							"coordinates": [data.track[i].events.speeding.location[1], data.track[i].events.speeding.location[0]]
						},
						"properties": {
							"latitude": data.track[i].events.speeding.location[0],
							"longitude": data.track[i].events.speeding.location[1],
							"speed": data.track[i].events.speeding.speed,
							"duration": data.track[i].events.speeding.duration
						}
					}]
				})
			}

			if (data.track[i].events.parking) {
				collection.parking.features.push({
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [data.track[i].events.parking.location[1], data.track[i].events.parking.location[0]]
					},
					"properties": {
						"latitude": data.track[i].events.parking.location[0],
						"longitude": data.track[i].events.parking.location[1],
						"start_at": data.track[i].timestamp,
						"duration": data.track[i].events.parking.duration,
						"icon": data.track[i].events.parking.duration > 3600 ? "track-parking-long-icon" : "track-parking-icon"
					}
				})
			}
		}

		return collection*/
	}, [track])

	const PARKING_LAYER = useMemo(() => {
			return {
				"id": "parking",
				"beforeId": beforeId ?? null,
				"metadata": {type: "parking"},
				"type": "symbol",
				"layout": {
					"icon-allow-overlap": true,
					"icon-image": {"type": "identity", "property": "icon"},
					"icon-size": 0.045,
					//"visibility": store.user.settings.map.parking ? "visible" : "none"
				}
			}
		}, []
	)

	const ARROWS_LAYER = useMemo(() => {
		return {
			"id": "arrows",
			"beforeId": "parking",
			"metadata": {type: "arrows"},
			"type": "symbol",
			layout: {
				"icon-image": "track-arrow-green-icon",
				"icon-size": 0.2,
				"icon-rotate": {"type": "identity", "property": "heading"},
			}
		}
	}, [])

	const LINE_LAYER = useMemo(() => {
		return {
			"id": "line",
			"beforeId": "arrows",
			"type": "line",
			"paint": {
				"line-color": "#68E931",
				"line-width": 5,
				"line-opacity": 1,
				/*"line-gradient": store.user.settings.map.temp ? collection.line.gradient : [
					"interpolate",
					["linear"],
					["line-progress"],
					0,
					'#C9C5BE',
					1,
					"#68E931"
				]*/
			},
			"layout": {"line-cap": "round", "line-join": "round"},
		}
	}, [])

	const BORDER_LINE_LAYER = useMemo(() => {
		return {
			"id": "border-line-layer",
			"beforeId": "line",
			"type": "line",
			"paint": {"line-color": "#000000", "line-width": 7},
			"layout": {"line-cap": "round", "line-join": "round"}
		}
	}, [])

	const TRANSPORT_LAYER = useMemo(() => {
		return {
			"id": "transport-track",
			//"beforeId": "line",
			"metadata": {type: "transport"},
			"type": "symbol",
			"layout": {
				"icon-allow-overlap": true,
				"icon-image": {"type": "identity", "property": "image"},
				"icon-size": 0.3,
				"icon-rotate": {"type": "identity", "property": "heading"}
			},
			"paint": {"icon-opacity": 0}
		}
	}, [])

	const SPEED_LAYER = useMemo(() => {
		return {
			"beforeId": "parking",
			"metadata": {type: "speed"},
			"type": "symbol",
			"layout": {
				"icon-allow-overlap": true,
				'icon-image': 'track-speed-icon',
				"icon-size": 0.3,
				"text-field": "{speed}",
				"text-allow-overlap": true,
				'text-font': ['Arial Unicode MS Bold'],
				'text-size': 12.5,
				//"visibility": store.user.settings.map.speed ? 'visible' : 'none'
			}
		}
	}, [])


	return (
		<>
			<Source type="geojson" data={collection.parking}>
				<Layer {...PARKING_LAYER}/>
			</Source>

			<Source type="geojson" data={collection.arrows}>
				<Layer {...ARROWS_LAYER}/>
			</Source>

			<Source type="geojson" data={collection.line.source} lineMetrics={true}>
				<Layer {...LINE_LAYER}/>
				<Layer {...BORDER_LINE_LAYER}/>
			</Source>

			<Source type="geojson" data={collection.transport}>
				<Layer {...TRANSPORT_LAYER}/>
			</Source>

			{/*{store.user.settings.map.speed && collection.speeding.map((speed, index) => (
				<Source key={"speed-layer-" + index} type="geojson" data={speed}>
					<Layer id={"speed-" + index} {...SPEED_LAYER}/>
				</Source>
			))}

			{collection.transport.features.length ?
				<Marker
					longitude={collection.transport.features[0].geometry.coordinates[0]}
					latitude={collection.transport.features[0].geometry.coordinates[1]}
					style={{pointerEvents: 'none !important;'}}
				>
					<TransportIcon heading={collection.transport.features[0].properties.heading}>
						<svg height="33.3" viewBox="0 0 100 111" fill="none">
							<g>
								<path
									d="M48.2273 -0.0450439C49.2575 -0.0450439 50.2879 -0.0450439 51.3182 -0.0450439C56.468 0.718821 60.1195 3.45177 62.2727 8.15382C74.4243 36.2642 86.5757 64.3746 98.7273 92.485C100.515 97.262 99.8782 101.677 96.8182 105.729C92.3227 110.639 86.9438 111.914 80.6818 109.558C70.9545 105.264 61.2273 100.969 51.5 96.6745C50.3058 96.2339 49.124 96.2646 47.9545 96.7646C38.2273 101.06 28.5 105.354 18.7727 109.649C13.5642 111.632 8.80655 110.88 4.49998 107.396C1.80467 104.922 0.289516 101.873 -0.0454712 98.2512C-0.0454712 97.7106 -0.0454712 97.1701 -0.0454712 96.6295C0.0997761 95.1519 0.448261 93.7103 0.999983 92.3048C13.1692 64.2018 25.3207 36.0914 37.4545 7.97363C39.6045 3.39471 43.1954 0.721823 48.2273 -0.0450439Z"
									fill="black"/>
								<path
									d="M49.1363 8.78449C51.4063 8.6395 53.0275 9.5705 54 11.5775C66.2377 39.8885 78.4498 68.2092 90.6363 96.5394C90.8812 98.954 89.8963 100.65 87.6818 101.63C86.6385 101.96 85.6083 101.929 84.5909 101.54C74.6598 97.1592 64.7204 92.7751 54.7727 88.3855C51.2924 87.0708 47.8379 87.1308 44.4091 88.5657C34.4735 93.0003 24.5039 97.3854 14.5 101.72C12.0956 102.113 10.3532 101.227 9.27271 99.0621C8.89353 98.0197 8.86322 96.9682 9.1818 95.9087C21.4236 67.6697 33.5752 39.5593 45.6363 11.5775C46.3307 10.0633 47.4973 9.13236 49.1363 8.78449Z"
									fill={TransportStates[collection.transport.features[0].properties.last_active_status].color}/>
							</g>
						</svg>
					</TransportIcon>
				</Marker> : null}*/}
		</>
	)
}

export default TransportTrackLayer

const TransportIcon = styled.div`
    cursor: pointer;
    transform: rotate(${props => props.heading}deg);
`
