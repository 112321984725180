import axios from "axios"

const HTTP = axios.create({})

HTTP.interceptors.request.use(async function (options = {external: true}) {
	return options
}, function (error) {
	//console.log("Request error: ", error)
})

HTTP.interceptors.response.use(response => {
	return {code: response.status, data: response.data}
}, error => {
	const json = error.toJSON()


	if (error.response?.data) {
		return {
			code: json.status,
			...error.response.data,
		}
	}

	return {
		success: false,
		code: json.status,
		error: json.message,
	}
})

export default HTTP
