import AppNavigation from "./navigation"
import {BrowserRouter} from "react-router-dom"
import {ConfigProvider} from "antd"
import "./css/index.css"
import "./css/slider.css"
import "./css/mapbox.css"
import Styled from "./inizialize/Styled"
import {MapProvider} from "react-map-gl"

const App = () => {
	return (
		<BrowserRouter>
			<ConfigProvider theme={{
				token: {
					fontFamily: "Inter",
					colorLink: "#000000",
					fontSize: 18,
					linkHoverDecoration: "underline"
				}
			}}>
				<Styled>
					<MapProvider>
						<AppNavigation/>
					</MapProvider>
				</Styled>
			</ConfigProvider>
		</BrowserRouter>
	)
}

export default App


