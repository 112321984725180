import React from "react"
import styled from "styled-components"

const StatusTag = ({color, text, style, ...props}) => {
	return (
		<TagWrapper color={color} style={style} {...props}>{text}</TagWrapper>
	)
}
export default StatusTag

const TagWrapper = styled.span`
    margin: 0;
    padding: 3px 5px;
    border-radius: 4px;
    align-self: flex-start;
    line-height: 10px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    background-color: ${props => props.color};
    color: #FFFFFF;
`
