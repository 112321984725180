import {useMemo} from "react"
import {Layer, Source} from "react-map-gl"

const PlacesLayer = () => {

	const data = useMemo(() => {
		const data = {
			type: "FeatureCollection",
			features: [{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [37.428407, 55.695073]
				}
			}]
		}

		return data
	}, [])

	return (
		<Source type="geojson" data={data} lineMetrics={true}>
			<Layer
				id="places"
				type="symbol"
				minzoom={0}
				layout={{
					"icon-allow-overlap": true,
					'icon-image': 'place-icon',
					"icon-size": 0.3
				}}/>
		</Source>
	)
}

export default PlacesLayer
