import styled from "styled-components"
import Mapbox from "../../../components/Mapbox"
import {useEffect, useState} from "react"
import mapboxgl from "mapbox-gl"
import {Button} from "antd"
import TerminalPointsLayer from "../../../components/Mapbox/components/Layers/TerminalPointsLayer"
import RoutesList from "./RoutesList"
import {getTrack} from "../../../library/methods/monitoring"
import PlacesLayer from "../../../components/Mapbox/components/Layers/PlacesLayer"
import TransportTrackLayer from "../../../components/Mapbox/components/Layers/TransportTrackLayer"
import http from "../../../library/network/http"
import {useMap} from "react-map-gl"
import dayjs from "dayjs"
import polyline from "@mapbox/polyline"

const Map = () => {
	const {"monitoring-main-map": mapRef} = useMap()

	const [loading, setLoading] = useState(false)
	const [routes, setRoutes] = useState([])
	const [activeRoute, setActiveRoute] = useState(null)
	const [track, setTrack] = useState(null)

	useEffect(() => {
		(async () => {
			setTrack(null)
			if (!activeRoute) return

			flyToRoute()
			const data = await getTrack(routes[activeRoute], setRoutes)
			if (data) setTrack(data)
		})()
	}, [activeRoute])

	const load = async () => {
		setLoading(true)
		const result = await http.get('/assets/json/routes.json')
		if (result.code === 200) {
			setRoutes(result.data)
		}
	}

	const flyToRoute = () => {
		if (!mapRef || !activeRoute) return

		const points = [[37.428407, 55.695073]]
		for (const point of routes[activeRoute].points) {
			points.push([point.location[1], point.location[0]])
		}

		if (!points.length) return

		const box = new mapboxgl.LngLatBounds(points[0], points[0])
		points.map(bound => {
			box.extend(bound)
		})

		mapRef.fitBounds(box, {
			padding: {
				top: 100,
				bottom: 130,
				left: window.innerWidth / 2 - 635,
				right: window.innerWidth / 2 - 635 + 300
			},
			maxZoom: 11.5,
			speed: 2
		})
	}

	const onClick = e => {
		/*const data = e.target.queryRenderedFeatures([e.point.x, e.point.y])
		const type = data[0]?.layer?.metadata?.type
		const id = data[0]?.properties?.id
		const route_id = data[0]?.properties?.route_id
		if (!route_id) return
		const tmp = [...routes]
		delete tmp.splice(route_id, 1)

		setRoutes(tmp)

		console.log(tmp)*/

		/*const point = store.points.items.find(point => point.id === id)
		if (!point) return
		if (store.terminal.activeRoute !== point.route_id) store.terminal.update('activeRoute', point.route_id)*/
	}


	const run = async () => {
		const data = [...routes]
		for (let i = 0; i < routes.length; i++) {
			const route = data[i]
			let shipments = route.points.map((point, index) => {
				delete point.statistic
				return {
					id: index,
					pickup: {
						id: index,
						location: [37.428407, 55.695073]
					},

					delivery: {
						id: index,
						service: 600,
						location: [point.location[1], point.location[0]],
						time_windows: [
							[dayjs(point.from_at).unix(), dayjs(point.to_at).unix()]
						]
					}
				}
			})

			let vehicles = [
				{
					id: 0,
					start: [37.428407, 55.695073],
					end: [37.428407, 55.695073]
				}
			]

			let result = await http.post("https://vrp.ms.ats24.ru", {shipments, vehicles})
			if (result.data.routes?.length) {
				let y = 0
				result.data.routes[0].steps.map(point => {
					switch (point.type) {
						case 'delivery': {
							route.points[point.id].position = y + 1
							route.points[point.id].arrived_duration = point.duration
							y++
							break
						}
					}
				})
				//route.track = result.data.routes[0].geometry
			}

			route.points.sort((a, b) => a.position - b.position)

			/*const json = JSON.stringify(route)
			const blob = new Blob([json], {type: 'application/json'})
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = i + '.json'

			// Имитация клика без добавления в DOM
			link.click()*/
		}

		console.log(data)
	}


	return (
		<Wrapper>
			<MapWrapper silver={!routes.length}>
				<Mapbox
					id="monitoring-main-map"
					maxZoom={20}
					onClick={onClick}
				>
					{/*<Button onClick={() => run()}>
						RUN
					</Button>*/}
					<TransportTrackLayer track={track} beforeId={null}/>
					<TerminalPointsLayer activeRoute={activeRoute} routes={routes}/>
					<PlacesLayer/>
				</Mapbox>
			</MapWrapper>
			<RoutesList routes={routes} activeRoute={activeRoute} setActiveRoute={setActiveRoute}/>
			<LoadDataWrapper show={!routes.length}>
				<ArrowWrapper>
					<img src="/assets/img/png/monitoring/arrow.png" height={550}/>
				</ArrowWrapper>
				<Button type="primary" onClick={() => load()} size="large">Загрузить</Button>
			</LoadDataWrapper>
		</Wrapper>
	)
}

export default Map

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    height: 550px;
`
const MapWrapper = styled.div`
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    flex: 1;
    filter: ${props => props.silver ? 'grayscale(100%)' : 'none'};
`
const LoadDataWrapper = styled.div`
    position: absolute;
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 100%;
    transition: background-color 0.5s, opacity 0.5s ease;
    opacity: 0;

    :hover {
        background-color: rgba(0, 0, 0, 70%);
        opacity: 1;
    }
`
const ArrowWrapper = styled.div`
    position: absolute;
`
