import {Map} from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import {useEffect, useRef, useState} from "react"
import sleep from "../../library/methods/sleep"
import PlaceIcon from "../../assets/img/icon/svg/place.svg"
import TrackArrowGreenIcon from "../../assets/img/icon/svg/track-arrow-green.svg"
import TrackArrowGreyIcon from "../../assets/img/icon/svg/track-arrow-grey.svg"
import TrackArrowBlueIcon from "../../assets/img/icon/svg/track-arrow-blue.svg"
import TrackArrowCyanIcon from "../../assets/img/icon/svg/track-arrow-cyan.svg"
import TrackArrowRedIcon from "../../assets/img/icon/svg/track-arrow-red.svg"
import TrackParkingIcon from "../../assets/img/icon/svg/track-parking.svg"
import TrackParkingLongIcon from "../../assets/img/icon/svg/track-parking-long.svg"
import TrackSpeedIcon from "../../assets/img/icon/svg/track-speed.svg"
import TransportArrowOnlineIcon from "../../assets/img/icon/svg/transport-arrow-online.svg"
import TransportArrowParkingIcon from "../../assets/img/icon/svg/transport-arrow-parking.svg"
import TransportArrowDowntimeIcon from "../../assets/img/icon/svg/transport-arrow-downtime.svg"
import TransportArrowHistoryIcon from "../../assets/img/icon/svg/transport-arrow-history.svg"

import SnowflakeIcon from "../../assets/img/icon/svg/snowflake.svg"
import LeafIcon from "../../assets/img/icon/svg/leaf.svg"

const icons = [

	{name: "place-icon", size: 100, source: PlaceIcon},
	{name: "track-arrow-green-icon", size: 64, source: TrackArrowGreenIcon},
	{name: "track-arrow-grey-icon", size: 64, source: TrackArrowGreyIcon},
	{name: "track-arrow-blue-icon", size: 64, source: TrackArrowBlueIcon},
	{name: "track-arrow-cyan-icon", size: 64, source: TrackArrowCyanIcon},
	{name: "track-arrow-red-icon", size: 64, source: TrackArrowRedIcon},
	{name: "track-parking-icon", size: 497, source: TrackParkingIcon},
	{name: "track-parking-long-icon", size: 497, source: TrackParkingLongIcon},
	{name: "track-speed-icon", size: 100, source: TrackSpeedIcon},
	{name: "transport-arrow-online", size: 100, source: TransportArrowOnlineIcon},
	{name: "transport-arrow-parking", size: 100, source: TransportArrowParkingIcon},
	{name: "transport-arrow-downtime", size: 100, source: TransportArrowDowntimeIcon},
	{name: "transport-arrow-history", size: 100, source: TransportArrowHistoryIcon},

	{name: "snowflake-icon", size: 100, source: SnowflakeIcon},
	{name: "leaf-icon", size: 100, source: LeafIcon}
]

const Mapbox = ({...props}) => {
	const mapRef = useRef()

	const [ready, setReady] = useState(false)

	useEffect(() => {
		if (!mapRef?.current || ready) return


		icons.map(icon => {
			if (!mapRef.current.hasImage(icon.name)) {
				const image = new Image(icon.size, icon.size)
				image.onload = () => mapRef.current.addImage(icon.name, image)
				image.src = icon.source
			}
		})

		setReady(true)
	}, [mapRef?.current])

	useEffect(() => {
		(async () => {
			await sleep(20)
			mapRef?.current?.resize()
		})()
	}, [])

	return (
		<Map
			ref={mapRef}
			initialViewState={{
				longitude: 37.5877594,
				latitude: 55.7421473,
				zoom: 10.2
			}}
			mapboxAccessToken="pk.eyJ1IjoidHJpbjRpayIsImEiOiJjbDFub21xcHYwcnR3M2Nxcms4YW53Mzl4In0.ncuH_mrNOECKI2J1TCemPg"
			mapStyle="mapbox://styles/trin4ik/cla5p19g600ry15mg7apzgg6a"
			touchZoomRotate={false}
			boxZoom={false}
			dragRotate={false}
			{...props}
		>
			{ready ? props.children : null}
		</Map>
	)
}

export default Mapbox
